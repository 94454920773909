import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "./App.css";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useState } from "react";
import { createTheme } from "@mui/material/styles";

function App() {
  const [spellOneTarget, setSpellOneTarget] = useState("enemy");
  const [spellTwoTarget, setSpellTwoTarget] = useState("enemy");
  const [spellThreeTarget, setSpellThreeTarget] = useState("enemy");
  const [spellFourTarget, setSpellFourTarget] = useState("enemy");
  const [macro1, setMacro1] = useState("");
  const [macro2, setMacro2] = useState("");
  const [macro3, setMacro3] = useState("");
  const [spells, setSpells] = useState(["", "", "", ""]);

  const makeTarget = (target, index) => {
    let tgt;
    if (target === "enemy") {
      tgt = `arena${index}`;
    } else if (index === 1 && target === "party") {
      tgt = "player";
    } else {
      tgt = `party${index - 1}`;
    }
    return tgt;
  };

  const generateMacros = () => {
    for (var i = 1; i < 4; i++) {
      let mac = `#showtooltip
/cast [mod:alt,exists,@${makeTarget(spellFourTarget, i)}]${
        spells[3]
      };[mod:ctrl,exists,@${makeTarget(spellThreeTarget, i)}]${
        spells[2]
      };[mod:shift,exists,@${makeTarget(spellTwoTarget, i)}]${
        spells[1]
      };[nomod,exists,@${makeTarget(spellOneTarget, i)}]${spells[0]};[mod:alt]${
        spells[3]
      };[mod:ctrl]${spells[2]};[mod:shift]${spells[1]};[nomod]${spells[0]}`;
      if (i === 1) {
        setMacro1(mac);
      } else if (i === 2) {
        setMacro2(mac);
      } else {
        setMacro3(mac);
      }
    }
  };
  return (
    <div className="App">
      <CssBaseline enableColorScheme />
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <Container maxWidth="lg">
          <Box>
            <h1>World of Warcraft Macro Generator</h1>
            <h3>Choose Target for each Spell</h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="spell-one-label">Spell One</InputLabel>
                  <Select
                    labelId="spell-one-label"
                    id="spell-one"
                    value={spellOneTarget}
                    label="Spell One"
                    onChange={(event) => setSpellOneTarget(event.target.value)}
                  >
                    <MenuItem value="enemy">Enemy</MenuItem>
                    <MenuItem value="party">Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="spell-two-label">Spell Two</InputLabel>
                  <Select
                    labelId="spell-two-label"
                    id="spell-two"
                    value={spellTwoTarget}
                    label="Spell Two"
                    onChange={(event) => setSpellTwoTarget(event.target.value)}
                  >
                    <MenuItem value="enemy">Enemy</MenuItem>
                    <MenuItem value="party">Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="spell-three-label">Spell Three</InputLabel>
                  <Select
                    labelId="spell-three-label"
                    id="spell-three"
                    value={spellThreeTarget}
                    label="Spell Three"
                    onChange={(event) =>
                      setSpellThreeTarget(event.target.value)
                    }
                  >
                    <MenuItem value="enemy">Enemy</MenuItem>
                    <MenuItem value="party">Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="spell-four-label">Spell Four</InputLabel>
                  <Select
                    labelId="spell-four-label"
                    id="spell-four"
                    value={spellFourTarget}
                    label="Spell Four"
                    onChange={(event) => setSpellFourTarget(event.target.value)}
                  >
                    <MenuItem value="enemy">Enemy</MenuItem>
                    <MenuItem value="party">Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <h3>Choose Spells</h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  disablePortal
                  id="spell1"
                  fullWidth
                  value={spells[0]}
                  onChange={(event) =>
                    setSpells([event.target.value, spells[1], spells[2], spells[3]])
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disablePortal
                  id="spell2"
                  fullWidth
                  value={spells[1]}
                  onChange={(event) =>
                    setSpells([spells[0], event.target.value, spells[2], spells[3]])
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disablePortal
                  id="spell3"
                  fullWidth
                  value={spells[2]}
                  onChange={(event) =>
                    setSpells([spells[0], spells[1], event.target.value, spells[3]])
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disablePortal
                  id="spell4"
                  fullWidth
                  value={spells[3]}
                  onChange={(event) =>
                    setSpells([spells[0], spells[1], spells[2], event.target.value])
                  }
                />
              </Grid>
            </Grid>
            <div style={{ margin: "2rem" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => generateMacros()}
              >
                Generate Macros
              </Button>
            </div>
            <h3>Macros</h3>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="macro1"
                  label="Macro 1"
                  multiline
                  maxRows={10}
                  value={macro1}
                  onChange={(event) => setMacro1(event.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="macro2"
                  label="Macro 2"
                  multiline
                  maxRows={10}
                  value={macro2}
                  onChange={(event) => setMacro2(event.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="macro3"
                  label="Macro 3"
                  multiline
                  maxRows={10}
                  value={macro3}
                  onChange={(event) => setMacro3(event.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
